.tab-container{
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: flex-end;
    background-color:#d9d9d9;
    padding: 0px 10px;
    border-bottom: 1px solid #708ea9;
}
.tab-container-item{
    display:flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #708ea9;
    border-bottom: none;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    min-height: 30px;
    text-align: center;
    font-size: .85rem;
    font-weight: 500;    
    padding: 0px 10px;
    user-select: none;
    cursor: pointer;  
    transition: all .1s ease-in-out;  
    background-color: #809bb3;
    &.selected{
        background-color: #bfcdd9;
        min-height: 34px;
    }
}