:root{
    --form-button-background: #0c5081;
    --form-button-border: #0c5081;
    --form-button-text: #F2F2F3;
    --form-button-hover: #1483d2;
    --form-button-hover-border: #1483d2;
    --grid-nav-button: #bfbfbf;
    --grid-nav-button-hover: #8fa7bc;
    --grid-nav-button-text: #333;
    --grid-toolbar-button: #339966;
    --grid-toolbar-hover: #40bf80;
    --grid-toolbar-text: #e6e6e6; 
    --link-button-background: transparent;
    --link-button-text: #0c5081;
    --nav-button-background: transparent;
    --nav-button-hover: #666666;
    --nav-button-selected: #809bb3;
    --nav-button-text: #F2F2F2;
}

.card-button{
    position: relative;
    background: var(--form-button-background);
    outline: none;
    border: 1px solid var(--form-button-border);
    border-radius:3px;
    padding: 0px 8px;    
    height: 30px;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.84rem;    
    color: var(--form-button-text);    
    cursor: pointer;
    transition: all 0.2s ease-in-out;  
    user-select: none;
    &:not(.disabled):hover{     
        background-color: var(--form-button-hover);
        border-color: var(--form-button-hover-border);
    }
    &:disabled,
    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:last-child {
        margin-right: 0px;
    }    
    width: auto;
    &.small{
        padding: 0px 6px;        
        height: 22px;  
        font-size: .75rem;  
    }
}

.grid-nav-button{
    position: relative;
    background: var(--grid-nav-button);
    outline: none;
    border: 1px solid var(--grid-nav-button);
    border-radius:3px;
    text-align: center;
    height: 24px;
    width:24px;
    font-family: inherit;
    font-weight: 400;    
    color: var(--grid-nav-button-text);    
    cursor: pointer;
    transition: all 0.2s ease-in-out;    
    padding-top: 2px;
    margin: 0px 2px;    
    &:not(.disabled):hover {
        background-color: var(--grid-nav-button-hover);
        border-color: var(--grid-nav-button-hover);
    }
    &.disabled{    
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:last-child {
        margin-right: 0px;
    }    
    
}

.link-button{    
    border: none;
    outline: none;
    background-color: var(--link-button-background);
    color: var(--link-button-text);
    font-size: .76rem;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    &:disabled,&[disabled]{opacity: .75;}
}

.nav-button{
    position: relative;
    width: 94%;
    margin: 0 auto;
    margin-left: 6px;
    margin-bottom: 10px;
    background: var(--nav-button-background);
    outline: none;    
    border:none;
    border-radius:3px;
    padding: 0px 8px;    
    height: 50px;
    font-size: 1.1rem;    
    font-weight: 300;
    color: var(--nav-button-text);    
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: left;
    &:hover{
        background-color: var(--nav-button-hover);        
    }  
    &.selected{
        background-color: var(--nav-button-selected);
    }
}
.nav-button-content{
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
}
.nav-button-content-left{
    width: 34px;
}
.nav-button-content-right{
    flex: 1 1 100%;
}
.grid-action-button{
    position: relative;
    background: var(--grid-toolbar-button);
    outline: none;
    border: 1px solid var(--grid-toolbar-button);
    border-radius:3px;
    padding: 0px 8px;    
    height: 28px;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.8rem;    
    color: var(--form-button-text);    
    cursor: pointer;
    transition: all 0.2s ease-in-out;  
    user-select: none;
    &:not(.disabled):hover{     
        background-color: var(--grid-toolbar-button);
        border-color: var(--grid-toolbar-button);
    }
    &:disabled,&[disabled] {
        opacity: 0.5;
        cursor: not-allowed;        
    }
    width: auto;    
}