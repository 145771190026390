:root {
    --folder-container-background: #fff;
    --new-message-button: #339966;
    --new-button-container-background: #e6e6e6;
    --personal-container-text: dodgerblue;
    --message-sender-text: #0c5081;
    --preview-header-background: #e6e6e6;
    --preview-header-border: #b3b3b3;
    --preview-avatar-border: #0c5081;
    --preview-avatar-text: #0c5081;
    --message-action-icons: #5b7c99;
    --message-action-border: #fff;
    --message-action-icon-disabled: #d9d9d9;
    --message-action-border-disabled: #d9d9d9;
    --folder-drag-hover: #ffc14d;
}

#messaging-wrapper {
    width: 100%;
    height: 100%;    
    padding: 5px;
}

.message-container{
    width: 100%;
    height: 100%;
    display: flex;    
}
.message-container-left,
.message-container-mid,
.message-container-right {
    display: flex;
    flex-flow: column;
    height: 100%;
    flex: 1;
}
.message-container-left {
    width: 300px;
    flex: none;
    border-right: 1px solid var(--card-border);
}
.message-container-mid {
    border-left: 1px solid var(--card-border);
    border-right: 1px solid var(--card-border);
}
.message-container-right {
    border-left: 1px solid var(--card-border);
}
.mailbox-container{
    padding: 10px 0px;
    height: 305px;
    border-bottom: 1px solid var(--grid-border);
    display: flex;
    flex-flow: column;

}
// .folder-container{
//     //padding: 5px 0px;
//     //flex:1;    
// }
#mail-container-title{
    display: flex;
    flex-flow: column;
    height: 100%;
}
.mail-row{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}
.mail-row-left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    font-size: 1.2rem;
}
.mail-row-mid,.mail-row-right{
    flex:1;
    display:flex;
    flex-flow: column;
}
.mail-row-right{
    flex:none;
    width:200px;
    
    text-align: right;
}

.mail-subject{
    color: var(--message-sender-text);
    font-weight: 500;
    font-size: 1.1rem;
}
.mail-sender{
    color: #333;
    font-weight: 300;
    font-size: .75rem;
}
.mail-icons{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;    
    justify-content: flex-end;    
}
#message-container {
    margin: 0px 3px;
}
.message-row-icon {
    width: 4%;
    text-align: center;
    padding-top: 3px;
    float: left;
}
.message-row-subject {
    width: 69%;
    h2,
    h4 {
        padding: 0;
        margin: 0;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--message-subject-text);
    }
    float: left;
}
.message-row-sender {
    width: 95%;
    h4 {
        padding: 0;
        margin: 0;
        margin-top: 2px;
        color: #595959;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    float: left;
}

.message-row-date {
    width: 27%;
    float: left;
    padding-top: 5px;
    text-align: right;
}

.message-row-text {
    width: 95%;
    margin-top: 3px;
    font-weight: 400;
    padding-left: 27px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.clearfix {
    clear: both;
}

.preview-container {
    flex: 1;
    margin-left: 3px;
}

.new-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: var(--new-button-container-background);
    border-bottom: 1px solid var(--preview-header-border);
    height: 80px;
}
.folder-row {
    height: 36px;
    width: 96%;
    margin: 0 auto;
    padding-top: 10px;
    border-radius: 5px;
    &:hover {
        background-color: var(--grid-row-hover);
    }
    &.selected {
        background-color: var(--grid-row-selected);
    }
    &.draghover {
        background-color: var(--folder-drag-hover);
    }
}
.folder-icon,
.folder-edit {
    width: 12%;
    float: left;
    pointer-events: none;
    text-align: center;
}
.folder-text {
    width: 76%;
    float: left;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.personal-container {
    width: 94%;
    font-size: 0.85rem;
    margin: 0 auto;
    font-weight: 500;
    color: var(--personal-container-text);
}
.personal-container-col {
    width: 48%;
    float: left;
}

.preview-header {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 5px;
    background-color: var(--preview-header-background);
    border-bottom: 1px solid var(--preview-header-border);
}
.preview-header-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
    margin-right: 10px;
}
.preview-header-text {
    flex: 1;
    justify-content: center;
    font-size: 0.85rem;
}
.preview-avatar-no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    color: var(--preview-avatar-text);
    font-size: 2.2rem;
    border: 2px solid var(--preview-avatar-border);
    border-radius: 50%;
    pointer-events: none;
    background-color: #f2f2f2;
}
.preview-avatar-image {
    width: 66px;
    height: auto;
    border-radius: 50%;
    border: 2px solid #fff;
    border-radius: 50%;
    pointer-events: none;
}
.preview-header-label {
    width: 11%;
    float: left;
    font-weight: 500;
}
.preview-header-data {
    width: 89%;
    float: left;
    font-weight: 400;
}
#preview-text {
    padding: 10px;
}
.preview-no-mail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.message-form-row-label {
    width: 150px;
}
.message-form-row-input {
    flex: 1;
}
.message-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: top;
}
.message-sort-container {
    width: 250px;
    margin-right: 3px;
}

.message-search-container {
    flex: 1;
    margin-left: 3px;
}
.message-action-container {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: red;
}
.message-action-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    //border: 2px solid #fff;
    background: var(--grid-toolbar-button);
    color: #fff;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    &.disabled {
        cursor: default;
        opacity: 0.5;
    }
}
.message-editor-prev {
    width: 100%;
    height: 150px;
    border: 1px solid var(--input-border);
    border-top: none;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    overflow: auto;
    color: #595959;
}
.message-editor-container {
    height: 190px;
    display: flex;
    flex-flow: column;
    width: 100%;
}
.ck-editor__editable_inline {
    min-height: 150px;
    max-height: 150px;
}
.ck-editor__editable_inline p {
    font-size: 14px !important;
    font-family: inherit !important;
}

.mail-drag-ghost {
    position: absolute;
    background: #e6d87f;
    color: #000;
    border: 1px solid #807219;
    border-radius: 3px;
    transform: translateX(-100vw);
    font-size: 0.85rem;
    font-weight: 500;
    padding: 10px;
}
