.ledger-wrapper{
    display: flex;
    width: 100%;
    height: 100%;    
    padding: 5px;
}
.ledger-tool-container{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--grid-row-header-background);
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    padding: 0px 8px;
    min-height: 36px;
}
.ledger-total-container{
    border-bottom:1px solid var(--grid-border);
    width: 100%;
    background-color: #e6e6e6;
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    padding: 8px;
    
}

.ledger-header-text {
    flex: 1;
}
.ledger-header-icon {
    width: 18px;
    color: #497ca1;
    cursor: pointer;
    &.filtered {color: green;}
}
.ledger-note-row{    
    max-height: 0px;    
    font-size: .85rem;    
    width:0px;
    min-width: 100%;
    color: #000;    
    visibility: hidden;   
    background-color: #d9f2e6;
    font-weight: 400;
    padding: 0px 5px 0px 40px;            
    &.open{        
        border: 2px solid var(--card-border);        
        padding: 10px 5px 10px 40px;            
        visibility: visible;
        max-height:150px;                
        overflow:auto;
        transition: all .25s ease-in; 
    }
}
.print-status{
    display: flex;
    width: 175px;
    align-items: center;
    background: var(--form-button-background);
    border: 1px solid var(--form-button-border);
    border-radius:3px;
    padding: 0px 8px;
    margin: 2px;
    height: 30px;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.86rem;
    color: var(--form-button-text);    
}
.ledger-toolbox-practice-label{
    width: 100px;    
    margin: 0px 5px;
}
.ledger-toolbox-practice-input{
    width :345px;
    padding-right: 5px;
    border-right: 1px solid #A6A6A6;    
}
.ledger-toolbox-date-label{
    width: 125px;    
    margin: 0px 5px;
}
.ledger-toolbox-date-input{
    width: 130px;
    padding-right: 5px;
    border-right: 1px solid #A6A6A6;    
}
.ledger-toolbox-link{        
    width: 150px;
    text-align:center;
    border-right: 1px solid #A6A6A6;        
    height: 28px;
    padding: 6px 10px;    
}
.ledger-custom-cell-left{
    width: 92%;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
.ledger-custom-cell-right{
    width: 8%;
    text-align: right;
    float: left;
    pointer-events: all;
    cursor: pointer;    
}
