:root{
    --login-miranda: #666666;
}

.login-container{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;    
    display: flex;
    flex-flow: column;
    .login-container-upper,
    .login-container-lower{ flex:1}
    .login-container-upper{        
        overflow: hidden;
        background-image: url(../images/login-background.jpg) ;        
        background-size: 100% 100%;
   }
}
.login-form-container{
    display: flex;    
    width: 100%;
    height: 100%;    
    padding-top:5px;
}

.login-form-container-left{
    width: 100px;    
    text-align: center;
}
.login-form-container-right{
    flex:1;
    padding-right: 5px;
}
.login-miranda{
    font-size: .70rem;
    text-align: center;
    margin-bottom: 5px;
    color: var(--login-miranda);
}
