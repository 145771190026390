:root {
    --grid-background: #ffffff;
    --grid-border: #bfbfbf;
    --grid-row-background: #ffffff;
    --grid-row-hover: #e8f4fd;
    --grid-row-selected: #bfcdd9;
    --grid-row-text: #404040;
    --grid-row-header-background: #d9d9d9;
    --grid-row-footer-background: #d9d9d9;
    --grid-nav-background: #d9d9d9;
    --grid-nav-text-background: #f2f2f2;
}
.grid-container {    
    display: flex;
    flex-flow: column;
    flex:1;
    
}
.grid-scroll {
    flex: 1 1 auto;
    height: 0;
    overflow-y: auto;
}
.grid-row,
.grid-row-header,
.grid-row-footer {
    width: 100%;
    color: var(--grid-row-text);
    align-items: center;
    border-bottom: 1px solid var(--grid-border);
    color: var(--grid-row-text);
    height: 28px;
    &.selected {
        background-color: var(--grid-row-selected);
    }
    &.selected:hover {
        background-color: var(--grid-row-selected);
    }
    &:not(.grid-row-header):hover,
    &:not(.grid-row-footer):hover {
        background-color: var(--grid-row-hover);
    }
}
.grid-row-header,
.grid.row-footer {
    background-color: var(--grid-row-header-background);
    //border-top: 1px solid var(--grid-border);        
}
.grid-row-header {
    top: 0;
    position: sticky;
}
.grid-row-footer {
    background-color: var(--grid-row-footer-background);
}

.grid-cell,
.grid-action-cell, 
.grid-header-cell{    
    float: left;
    border-right: 1px solid var(--grid-border);
    height: 100%;
    padding: 5px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    pointer-events: none;
    float: left;
    font-size: 0.90rem;
    font-weight: 400;
    color: #666666;
}
.grid-action-cell {
    pointer-events: all;
    padding: 5px 8px 5px 5px;
    .inner {
        width: 100%;
        height: 100%;
        &.pointer {
            cursor: pointer;
        }
    }
}
.grid-header-cell{
    font-weight: 500;
    color: #404040;

}




.grid-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--grid-row-header-background);
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    height: 38px;
    padding: 5px;
}
.grid-toolbar-header{
    font-weight: 500;
    flex:1;
    font-size: 1.25rem;
}
.grid-nav-container {
    background-color: var(--grid-nav-background);
    color: var(--grid-page-nav-icon);
    width: 100%;
    padding: 5px;
    .nav-region {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .nav-icon {
        width: 24px;
        text-align: center;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        &.disabled {
            cursor: auto;
            pointer-events: none;
            color: var(--grid-page-nav-icon-disable);
        }
    }
    .page-input {
        background: var(--grid-nav-text-background);
        width: 110px;
        padding: 5px;
        margin: 0px 3px;
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--grid-page-nav-text);
        text-align: center;
        pointer-events: none;
        user-select: none;
        height: 24px;
        border-radius: 3px;
    }
}
.grid-loader-spinner {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    color: #00b377;
    font-size: 0.8rem;
    font-weight: 400;
}
