.admin-wrapper{
    width: 100%;
    height: 100%;    
    display: flex;
    flex-flow: column;
}
.admin-tab-container{
    height: 40px;
    display: flex;
    align-items: center;    
}
.admin-main-container{
    display: flex;
    flex-flow: column;
    flex:1;
    padding: 5px;     
}

.admin-container{
    width: 100%;
    height: 100%;    
    display:flex;
}
.admin-left-container,.admin-right-container{
    flex:1;
    display: flex;
    flex-flow: column ;
    height: 100%;
}
.admin-left-container{border-right: 1px solid var(--card-border);}
.admin-right-container{border-left: 1px solid var(--card-border);}
.admin-top-container,.admin-bot-container{
    flex:1;
    display: flex;
    flex-flow: column ;
}
.admin-top-container{border-bottom: 1px solid var(--card-border);}
.admin-bot-container{border-top: 1px solid var(--card-border);}
    
.admin-tab-wrapper{    
    display: flex;
    align-items: center;    
}
.admin-practice-container{    
    height: 100%;
    flex:1 1 100%;
    margin-right: 3px;
}
.admin-user-container{    
    display: flex;
    flex-flow: column;
    flex: 1 1 100%;
    height: 100%;        
    margin-left: 3px;
}
.admin-user-container-top,
.admin-user-container-bottom{
    flex:1;
    width: 100%;
    margin-bottom: 3px;
}
.admin-user-container-bottom{
    margin-bottom: 0px;
    margin-top: 3px;
}
