@use "./inputs";
@use "./buttons";
@use "./cards";
@use "./forms";
@use "./grid";
@use "./tabs";
@use "./login";
@use "./portal";
@use "./admin";
@use "./settings";
@use "./files";
@use "./messaging";
@use "./ledger";
@use "./password";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

*{box-sizing: border-box;}

body {
    margin: 0;
    font-size: 16px;
    font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

svg{
  pointer-events: none;
}

.application-modal{
  display: flex;
  align-items: center;  
  justify-content: center;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.30);  
  z-index: 999999;
  &.nodim{
    background-color: transparent;  
  }
}