.form-card-content{
    padding: 10px 5px;
}
.form-card-footer{
    &.left{flex:1;}
    &.button{margin-left:5px;}
}
.form-row{
    display: flex;
    align-items: center;
    width: 100%;
}