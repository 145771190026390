:root{
    --portal-background: #F2F2F2;
    --portal-top-nav: #435b70;
    --portal-footer: #435b70;
    --portal-footer-text: #FFF;
    --portal-nav-background: #404040;
    --portal-nav-avatar-text:#F2F2F2;
    --portal-nav-avatar-border: #f2f2f2;
}

.portal-container{
    position:absolute;
    top:0;
    left:0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: var(--portal-background);
}
.portal-top-nav{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--portal-top-nav);
    padding: 0px 5px;
}
.portal-top-container{
    flex:1
}






.portal-content{
    flex:1 1 auto;
    display: flex;    
    background-color: var(--portal-background);
}
.portal-footer{
    display: flex;
    align-items: center;
    height: 30px;
    background-color: var(--portal-footer) ;
    color: var(--portal-footer-text);
    font-size: .7rem;
    padding: 0px 5px;
}
.portal-nav{
    max-width: 220px;
    height: 100%;
    background-color: var(--portal-nav-background);
    hr{
        width: 94%;
        border: none;
        border-top: 1px dotted var(--portal-nav-avatar-border);
    }
}
.portal-nav-avatar-container{
    width: 130px;    
    text-align: center;
    margin: 0 auto;  
    cursor:pointer;      
}
.portal-nav-avatar-no-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
    color: var(--portal-nav-avatar-text);
    font-size: 2.4rem;
    border: 2px solid var(--portal-nav-avatar-border);
    border-radius: 50%;
    pointer-events: none;
}
.portal-nav-avatar-image{
    width: 130px;
    height: auto;
    border-radius: 50%;
    border: 2px solid var(--portal-nav-avatar-border);
    border-radius: 50%;
    pointer-events: none;
}
.portal-nav-avatar-text{
    width: 100%;
    text-align:center;
    font-size: .76rem;
    color: var(--portal-nav-avatar-text);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select:none;
}