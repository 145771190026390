.settings-wrapper{
    width: 100%;
    height: 100%;            
    display: flex;
    flex-flow: column;
}
.settings-tab-container{
    height: 40px;
    display: flex;
    align-items: center;    
}
.settings-main-container{
    display: flex;
    flex-flow: column;
    flex:1;
    padding: 5px;     
}
.settings-container{
    width: 100%;
    height: 100%;    
    display:flex;
}
.payfor-container,
.paymethod-container,
.paysource-container,
.settings-user-container,
.settings-provider-container{    
    width: 100%;
    height: 100%;
    display:flex;
    flex-flow: column;
}
.payfor-container,
.settings-user-container{
    border-right:1px solid var(--card-border);
}
.paymethod-container{
    border-right:1px solid var(--card-border);
    border-left:1px solid var(--card-border);
}
.paysource-container,
.settings-provider-container{
    border-left:1px solid var(--card-border);
}