.password-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;    
    padding: 5px;
}
.password-container{
    width:100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}
.password-container-top{
    flex:1;
    display:flex;
    width: 100%;    
    border-bottom: 1px solid var(--card-border);
}
.password-container-top-left{
    flex:1;
    height: 100%;
    border-right:1px solid var(--card-border);
    display: flex;
    flex-flow: column;
}
.password-container-top-right{
    width: 600px;
    height: 100%;
    display: flex;
    border-left:1px solid var(--card-border);
    flex-flow: column;
}
.password-container-bottom{    
    height: 300px;
    display:flex;
    flex-flow: column;
    width: 100%;    
    border-top: 1px solid var(--card-border);
}
.password-container-top-right-top{
    flex:1;
    border-bottom: 1px solid var(--card-border);
    display: flex;
    flex-flow: column;
}
.password-container-top-right-bot{
    height: 200px;
    border-top: 1px solid var(--card-border);
    display: flex;
    flex-flow: column;
}
.password-addinfo-container{
    width: 100%;
    height: 100%;
    padding: 5px;
}
.copy-column{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: all;
}
.copy-column-text{
    flex:1;
}
.copy-column-icon{
    width: 20px;
    text-align: center;
    font-size: 1.1rem;
    cursor:pointer;    
    padding-top: 4px;
}
#copy-wrapper{
    position:absolute;
    padding: 6px;
    background-color: #e6d87f;
    border: 1px solid #807219;
    border-radius: 10px;
    color: #333;
    opacity: 0;    
    transition: opacity 500ms ease 0s;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    font-size: .75rem;
    font-weight: 400;    
    &.open{opacity: 1;}
}
