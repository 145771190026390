:root {
    --filename-text: #435b70;
    --file-uploaded: #339966;
    --file-downloaded: #435b70;
    --file-deleted: #ff4d4d;
    --file-dd-container-text: #999999;
    --file-dd-container-border: #999999;
    --file-dd-container-text-hover: #339966;
    --file-dd-container-border-hover: #339966;
}

.files-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px;
}
.files-container {
    height: 100%;
    display: flex;
    flex-flow: column;    
}
.files-container-top {
    flex: 1;
    display: flex;
    border-bottom: 1px solid var(--card-border);

    
}
.files-container-top-left,
.files-container-top-right{
    flex:1;
    height: 100%;    
    display: flex;
    flex-flow: column;

}
.files-container-top-left{border-right: 1px solid var(--card-border);}
.files-container-top-right{border-left: 1px solid var(--card-border);}
.files-container-bot {
    height: 300px;    
    display: flex;
    flex-flow: column;
    border-top: 1px solid var(--card-border);
}
#files-drop-wrapper {
    width: 100%;
    padding: 6px;
}
#files-drop-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed var(--file-dd-container-border);
    border-radius: 3px;
    color: var(--file-dd-container-text);
    user-select: none;
    padding: 20px;
    cursor:pointer;    
    &.dragover {
        color: var(--file-dd-container-text-hover);
        border-color: var(--file-dd-container-border-hover);
    }    
    h2 {
        padding: 0;
        margin: 10px 0px 4px 0px;
    }
    h3 {
        padding: 0;
        margin: 4px 0px 10px 0px;
    }
}

.files-grid-row {
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid var(--grid-border);
}
.files-icon {
    width: 8%;
    height: 100%;
    text-align: center;
    float: left;
}
.files-name {
    width: 50%;
    height: 100%;
    user-select: none;
    pointer-events: none;
    float: left;
    padding-top: 8px;
    h2 {
        padding: 0;
        margin: 3px 0px 2px 0px;
        font-size: 1.25rem;
        font-weight: 400;
        color: var(--filename-text);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.86rem;
        color: #333;
    }
}
.files-status {
    width: 26%;
    height: 100%;
    float: left;
    padding-top: 9px;
    .files-status-left {
        width: 30%;
        float: left;
    }
    .files-status-right {
        width: 70%;
        float: left;
    }
    font-weight: 500;
}
.files-action {
    width: 8%;
    height: 100%;
    text-align: center;
    float: left;
    padding-top: 10px;
    pointer-events: all;
    cursor: pointer;
}
.files-action-uploaded,
.files-action-downloaded,
.files-action-deleted {
    color: var(--file-uploaded);
    font-weight: 500;
}
.files-action-downloaded {
    color: var(--file-downloaded);
}
.files-action-deleted {
    color: var(--file-deleted);
}

.files-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: var(--grid-row-header-background);
    padding: 5px;
    border-bottom: 1px solid var(--grid-border);
    color: #333;
}
.files-grid-toolbar-left {
    flex: 1;
    display: flex;
    flex-flow: column;
    margin: 5px 10px 0px 0px;
}
.files-grid-toolbar-right {
    width: 120px;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.files-grid-toolbar-button {
    height: 100%;
}
.files-grid-toolbar-input {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.files-grid-toolbar-label {
    width: 90px;
}
.files-grid-toolbar-field {
    flex: 1;
}
.files-dd-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#files-upload-top {
    width: 100%;
    padding: 10px;
}
#files-upload-top-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border: 3px dashed var(--file-dd-container-border);
    color: var(--file-dd-container-text);
    user-select: none;
    &.dragover {
        color: var(--file-dd-container-text-hover);
        border-color: var(--file-dd-container-border-hover);
    }
    pointer-events: none;
    h2 {
        padding: 0;
        margin: 10px 0px 4px 0px;
    }
    h3 {
        padding: 0;
        margin: 4px 0px 10px 0px;
    }
}
.files-upload-bottom {
    flex: 1;
    display: flex;
    flex-flow: column;
    width: 100%;
}

.files-list-scroller {
    flex: 1 1 auto;
    height: 0;
    overflow-y: auto;
}
