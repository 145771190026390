:root{
    --card-background: #FFFFFF;
    --card-border: #5b7c99;
    --card-shadow:rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    --card-header-background: #5b7c99;
    --card-header-text: #FFFFFF;
    --card-header-icon: #E8F4FD;
    --card-toolbar-background: #d9d9d9;
    --card-toolbar-border: #bfbfbf;



    --card-footer-background: #F2F2F2;
    --status-card-background: #FFF;
    --status-card-shadow:rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    --status-card-success: #339966;
    --status-card-info: #e6b800;
    --status-card-error: #ff4d4d;
}

.card-container{
    display: flex;
    flex-flow:column;
    width: 400px;
    border: 2px solid var(--card-border);
    border-top: none;
    background-color: var(--card-background);
    box-shadow:var(--card-shadow);
    border-radius: 3px;
    &.noshadow{box-shadow: none;}
    &.bordertop{border-top: 2px solid var(--card-border);}
}

.card-header,.card-footer{
    display: flex;
    align-items: center;
    background-color: var(--card-header-background);
    height: 36px; 
    min-height: 36px;
    padding: 3px 5px;
    font-family: inherit;    
    color: var(--card-header-text);    
    font-weight: 400;    
}

.card-header-text{
    flex:1;
    font-family: inherit;
    font-size: 1rem;    
    color: var(--card-header-text);    
    font-weight: 400;
}

.card-header-icon{
    width: 20px;
    font-size: 1rem;    
    color: var(--card-header-icon);    
}

.card-toolbar{
    display: flex;
    align-items: center;
    color: #333;
    font-weight: 400;    
    height: 38px;
    min-height: 38px;
    width: 100%;
    background-color: var(--card-toolbar-background);    
    padding: 3px 5px;
    border-bottom: 1px solid var(--grid-border);
}

.card-toolbar-title{
    font-size: 1.2rem;
    font-weight: 500;
    flex:1
}


.card-content{   
    background-color: var(--card-background);    
    width: 100%;    
    flex:1;
}
.card-footer{
    background-color: var(--card-footer-background);
    padding: 3px 5px;
}


.status-card{
    position: absolute;
    left: calc(50% - 138px);
    top: 0px;
    background-color: var(--status-card-background);
    width: 275px;
    max-height: 0px;    
    padding: 10px;
    visibility: hidden;
    overflow-y: hidden;
    transition: all .2s ease-in-out;
    margin: 10px auto;
    border-radius: 3px;
    box-shadow: 3px 3px 10px #4d4d4d;      
    border-left:8px solid var(--status-card-background);
    &.open {
        visibility: visible;
        max-height: 250px;
    }
    &.success {
        border-left-color: var(--status-card-success);
    }
    &.info{    
        border-left-color: var(--status-card-info);
    }
    &.error {
        border-left-color: var(--status-card-error);
    }        
}
.status-card-close {
    position: absolute;
    left: 96%;
    top: -8px;
    color: #999999;
    cursor: pointer;
    font-size: 1.25rem;
}
.status-card-content {
    display: flex;
    align-items: center;
    width: 100%;
}
.status-card-icon {
    text-align: center;
    font-size: 3rem;    
    margin-bottom: 10px;
    &.success{
        color: var(--status-card-success);
    }
    &.info {
        color: var(--status-card-info);
    }
    &.error {
        color: var(--status-card-error);
    }
}
.status-card-text {
    text-align: center;
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.status-card-foot {
    display: flex;    
    justify-content: center;
}

.yesno-card{
    position: absolute;    
    background-color: var(--status-card-background);
    width: 300px;
    max-height: 0px;
    padding: 10px;
    visibility: hidden;
    overflow-y: hidden;    
    border-radius: 3px;
    z-index: 999999;
    box-shadow: 3px 3px 10px #4d4d4d;      
    border: 2px solid var(--status-card-info);
    transition: all 0.2s ease-in;
    &.open {
        visibility: visible;
        max-height: 200px;
    }
    svg{color:var(--status-card-info)}
}