:root {
    --input-border: #bfbfbf;
    --input-border-focus: #8ac8f4;
    --input-error: #ff8080;
    --input-background: #ffffff;
    --input-text: #404040;
    --input-label: #808080;
    --input-select-delete: #ff8080;
    --input-select-toggle: #999999;
    --input-select-background: #f2f2f2;
    --input-select-row-hover: #e8f4fd;
    --input-select-selected-multi-background: #F2F2F2;
    --input-select-selected-multi-border: #BFBFBF;
    --input-select-selected-multi-icon: #ff8080;
    --input-check-background: ##FFFFFF;
    --input-check-border: #BFBFBF;    
    --input-check-hover: #E8F4FD;
    --input-check: #0c5081;
}

input,textarea {
    height: 28px;
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: 3px;
    background-color: var(--input-background);
    color: var(--input-text);
    font-family: inherit; 
    font-weight: 400;
    outline: none;
    padding-left: 5px;
    font-size: .84rem;
    &:focus {
        border-color: var(--input-border-focus);
    }
    &.error {
        border-color: var(--input-error);
    }
    &.hidden{
        display:none;
    }
}
input[type="search"]{
    background-image: url(../images/search-glass.png);
    background-position: 98% ;
    background-repeat: no-repeat;
}
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }




textarea{
    resize: none;
}

label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--input-label);    
    padding-left: 3px;
    margin-bottom: 1px;
    user-select: none;
}

.input-error {
    margin: 2px 0px 4px 0px;
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--input-error);
    padding-left: 3px;
    height: 14px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    &.row {
        flex-direction: row;
        align-items: center;
        &.column {
            flex-direction: column;
        }
    }
}

.input-select-container {    
    display:flex;
    align-items: center;    
    min-height: 28px;
    width: 100%;
    border: 1px solid var(--input-border);
    border-radius: 3px;
    background-color: var(--input-background);
    color: var(--input-text);
    font-family: inherit; 
    padding-left: 5px;
    &:focus {border-color: var(--input-border-focus);}
    &.error {border-color: var(--input-error);}   
    &.toggle { border-bottom-left-radius: 0px;border-bottom-right-radius:0px ;}
    cursor:pointer; 
}

.input-select-container .selected{flex:1;pointer-events: none;font-family: inherit;font-weight: 400;}

.input-select-container .delete,
.input-select-container .toggle{
    width: 24px;
    text-align: center;
    cursor: pointer;    
    & svg{animation: toggle-close .25s forwards;} 
    & svg.toggle{animation: toggle-open .25s forwards;}
}
.input-select-container .toggle{ font-size:1.2rem;}
    
@keyframes toggle-open {
    from {transform: rotate(0deg);}    
    to {transform: rotate(-90deg);}    
}
@keyframes toggle-close {
    to {transform: rotate(0deg);}    
    from {transform: rotate(-90deg);}    
}

.input-select-container .toggle{color: var(--input-select-toggle);pointer-events: none;}
.input-select-container .delete{color: var(--input-select-delete);}

.input-select-list-wrapper{
    position:relative;
    width: 100%; 
}

.input-select-list-container{
    position:absolute;
    max-height: 0px;
    top: -1px;
    width:100%; 
    border: 1px solid var(--input-border);
    visibility: hidden;     
    transition: none;
    z-index: 10000;
    background-color: var(--input-select-background);
    &.toggle{
        visibility: visible;
        max-height: 200px;
        overflow-y: scroll;
        transition: all .25s ease-in-out;
    }
}

.input-select-list-item{
    font-family: inherit;
    font-size: .84rem;
    font-weight: 400;
    color: var(--input-text);
    padding: 5px 3px;
    border-bottom: 1px solid var(--grid-border);
    cursor: pointer;
    &:hover{
        background-color: var(--input-select-row-hover);
    }
}
.input-selected-single{
    color: var();
    font-size: .84rem;
}

.input-selected-multi{
    padding: 3px;
    border: 1px solid var(--input-select-selected-multi-border);
    background-color: var(--input-select-selected-multi-background);
    border-radius: 3px;
    font-size: .75rem;
    color: var(--input-text);    
    float:left;
    margin: 2px;
    pointer-events: all;
    & .text{
        max-width: 100px;
        float:left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;   
        user-select: none; 
        pointer-events: none;
    }
    & .icon{
        width: 15px;
        float:left;
        text-align: center;
        color: var(--input-select-selected-multi-icon);
        pointer-events:none;
    }
}
.input-check-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
}

.input-check-container{
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
    padding-top: 3px;
}

.input-check-container input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;    
    font-size: 16px;
    font-family: inherit;
    font-weight: 400;

}
.input-check-container .checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid var(--input-border);
    border-radius: 3px;    
    background-color: var(--input-background);
}
.input-check-container:hover input ~ .checkmark{
    background-color: var(--input-check-hover);
}
.input-check-container .checkmark:after{
    content: "";
    position: absolute;
    display: none;
}
.input-check-container input:checked ~.checkmark:after{
    display: block;
}
.input-check-container .checkmark:after{
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid var(--input-check);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}


